export const types = {
  SET_TAB: "[Action Plans] Set Tab",
  SET_ACTION_PLAN_LIST: "[Action Plans] Set Action Plan List",
  SORT_ASSIGNED_SURVEYS: "[Action Plans] Sort Assigned Surveys",
  SET_ACTION_PLAN: "[Action Plans] Set Action Plan",
  SET_LIST_ACTION_PLANS_PAGE: "[Action Plans] Set List Action Plans Page",
  SET_LIST_ACTION_PLANS_SORT: "[Action Plans] Set List Action Plans Sort",
  SET_CLONE_ACTION_PLAN: "[Action Plans] Set Clone Action Plan",
  SET_ACTION_PLAN_STEP: "[Action Plans] Set Action Plan Step",
  SET_QUESTION_TITLE: "[Action Plans] Set Question Title",
  SET_QUESTION_ANSWER: "[Action Plan] Set Question Answer",
  DELETE_ANSWER: "[Action Plan] Delete Answer",
  REMOVE_QUESTION: "[Action Plan] Remove Question",
  SWAP_QUESTIONS: "[Action Plan] Swap Questions",
  ADD_QUESTION: "[Action Plan] Add Question",
  ADD_LABEL: "[Action Plan] Add Label",
  SET_SUGGESTED_ACTIONS: "[Action Plan] Set Suggested Actions",
  SET_SUGGESTED_PHRASES: "[Action Plan] Set Suggested Phrases",
  ADD_NEW_RESOURCE: "[Action Plan] Add New Resource",
  DELETE_RESOURCE: "[Action Plan] Delete Resource",
  SET_RESOURCE_TITLE: "[Action Plan] Set Resource Title",
  SET_RESOURCE_URL: "[Action Plan] Set Resource Url",
  SET_RESOURCE_MEDIA_TYPE: "[Action Plan] Set Resource Media Type",
  SET_RESOURCE_DESCRIPTION: "[Action Plan] Set Resource Description",
  SET_FORM_ERRORS: "[Action Plan] Set Form Errors"
}

export const setTab = type => ({
  type: types.SET_TAB,
  payload: { type }
})

export const setActionPlanList = actionPlans => ({
  type: types.SET_ACTION_PLAN_LIST,
  payload: { actionPlans }
})

export const sortAssignedSurveys = sortBy => ({
  type: types.SORT_ASSIGNED_SURVEYS,
  payload: { sortBy }
})

export const setActionPlan = actionPlan => ({
  type: types.SET_ACTION_PLAN,
  payload: { actionPlan }
})

export const setListActionPlansPage = page => ({
  type: types.SET_LIST_ACTION_PLANS_PAGE,
  payload: { page }
})

export const setListActionPlansSort = title => ({
  type: types.SET_LIST_ACTION_PLANS_SORT,
  payload: { title }
})

export const setCloneActionPlan = actionPlans => ({
  type: types.SET_CLONE_ACTION_PLAN,
  payload: { actionPlans }
})

export const setActionPlanStep = step => ({
  type: types.SET_ACTION_PLAN_STEP,
  payload: { step: step?.getActionPlanStep?.actionPlanStep }
})

export const setQuestionTitle = (title, id, stepQuestionId) => ({
  type: types.SET_QUESTION_TITLE,
  payload: { title, id, stepQuestionId }
})

export const setQuestionAnswer = (
  answer,
  index,
  questionId,
  stepQuestionId
) => ({
  type: types.SET_QUESTION_ANSWER,
  payload: { answer, index, questionId, stepQuestionId }
})

export const deleteAnswer = (index, questionId, stepQuestionId) => ({
  type: types.DELETE_ANSWER,
  payload: { index, questionId, stepQuestionId }
})

export const addQuestion = (questionIndex, stepQuestionId) => ({
  type: types.ADD_QUESTION,
  payload: { questionIndex, stepQuestionId }
})

export const removeQuestion = (index, stepQuestionId) => ({
  type: types.REMOVE_QUESTION,
  payload: { index, stepQuestionId }
})

export const swapQuestions = (aIndex, bIndex, stepQuestionId) => ({
  type: types.SWAP_QUESTIONS,
  payload: { aIndex, bIndex, stepQuestionId }
})

export const addLabel = (questionId, stepQuestionId) => ({
  type: types.ADD_LABEL,
  payload: { questionId, stepQuestionId }
})

export const setSuggestedActions = (actions, stepQuestionId) => ({
  type: types.SET_SUGGESTED_ACTIONS,
  payload: { actions, stepQuestionId }
})

export const setSuggestedPhrases = (phrases, stepQuestionId) => ({
  type: types.SET_SUGGESTED_PHRASES,
  payload: { phrases, stepQuestionId }
})

export const addNewResource = (emptyResource, stepQuestionId) => ({
  type: types.ADD_NEW_RESOURCE,
  payload: { emptyResource, stepQuestionId }
})

export const deleteResource = (resourceIndex, stepQuestionId) => ({
  type: types.DELETE_RESOURCE,
  payload: { resourceIndex, stepQuestionId }
})

export const setResourceTitle = (title, resourceIndex, stepQuestionId) => ({
  type: types.SET_RESOURCE_TITLE,
  payload: { title, resourceIndex, stepQuestionId }
})

export const setResourceUrl = (url, resourceIndex, stepQuestionId) => ({
  type: types.SET_RESOURCE_URL,
  payload: { url, resourceIndex, stepQuestionId }
})

export const setResourceMediaType = (
  mediaType,
  resourceIndex,
  stepQuestionId
) => ({
  type: types.SET_RESOURCE_MEDIA_TYPE,
  payload: { mediaType, resourceIndex, stepQuestionId }
})

export const setResourceDescription = (
  description,
  resourceIndex,
  stepQuestionId
) => ({
  type: types.SET_RESOURCE_DESCRIPTION,
  payload: { description, resourceIndex, stepQuestionId }
})

export const setFormErrors = (field, error) => ({
  type: types.SET_FORM_ERRORS,
  payload: { field, error }
})
