import { Box, CustomHooks, Flex } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import SliceAndDiceUpsellIAS from "../../../../sliceAndDice/components/SliceAndDiceUpsell/SliceAndDiceUpsellIAS"
import ReportCard from "../../../components/ReportCard"
import {
  getReportResponse,
  getReportResponseId,
  getSliceAndDiceReport
} from "../../../store/report.selectors"
import CircularScore from "../../containers/CircularScore"
import actionPlanItems from "../../lib/actionPlan.json"
import retailTrustOrganisations from "../../lib/retailTrustOrganisations"
import retailTrustAPI from "../../lib/retailTrust_actionPlan.json"
import ActionPlanStep from "./ActionPlanStep"
import QuestionIntro from "./QuestionIntro"
import ResolveQuestions from "./ResolveQuestions"
import SuggestedActions from "./SuggestedActions"
import SuggestedPhrases from "./SuggestedPhrases"

const SectionGrid = styled(Box)`
  padding: 40px 0px;
  align-items: flex-start;
  display: grid;
  grid-template-columns: ${({ device }) =>
    device === "MOBILE" ? "100%" : "70% 30%"};
  grid-template-rows: auto auto;
  grid-template-areas: ${({ device }) =>
    device === "MOBILE"
      ? `"B"
   "A"
   "C"`
      : `"A B"
   "A C"`};
  grid-gap: ${({ device }) => (device === "MOBILE" ? "24px" : "0px")};
`

const CustomFlex = styled(Flex)`
  grid-area: A;
`

const PDFView = ({ heading, device, step, questionList }) => (
  <>
    {questionList?.map(
      (
        {
          title,
          resolveQuestions,
          suggestedActions,
          id,
          suggestedPhrases,
          score
        },
        i
      ) => (
        <>
          <ReportCard width={1 / 1} heading={heading} exportingPDF>
            <SectionGrid key={id} width={1 / 1} device={device}>
              {i === 0 && <CircularScore step={step} />}
              <CustomFlex flexWrap="wrap" pr={[0, "5%"]}>
                <QuestionIntro title={title} score={score} />
                <ResolveQuestions resolveQuestions={resolveQuestions} />
              </CustomFlex>
            </SectionGrid>
          </ReportCard>
          <ReportCard width={1 / 1} heading={heading} exportingPDF>
            <SectionGrid key={id} width={1 / 1} device={device}>
              {i === 0 && <CircularScore step={step} />}
              <CustomFlex flexWrap="wrap" pr={[0, "5%"]}>
                <QuestionIntro title={title} score={score} />
                <SuggestedActions suggestedActions={suggestedActions} />
              </CustomFlex>
            </SectionGrid>
          </ReportCard>
          <ReportCard width={1 / 1} heading={heading} exportingPDF>
            <SectionGrid key={id} width={1 / 1} device={device}>
              {i === 0 && <CircularScore step={step} />}
              <CustomFlex flexWrap="wrap" pr={[0, "5%"]}>
                <QuestionIntro title={title} score={score} />
                <SuggestedPhrases suggestedPhrases={suggestedPhrases} />
              </CustomFlex>
            </SectionGrid>
          </ReportCard>
        </>
      )
    )}
  </>
)

const PageManagement = ({ step, stepQuestions, orgId, exportingPDF }) => {
  const [questionList, setQuestionList] = useState([])
  const isSliceAndDiceMember = useSelector(getSliceAndDiceReport)?.active
  const reportId = useSelector(getReportResponseId)

  const device = CustomHooks.useClientDevice()

  const surveyReport = useSelector(getReportResponse)
  const surveyReportSixStep = surveyReport?.data?.surveySixSteps.find(
    i => i.name === step
  )

  if (!isSliceAndDiceMember) {
    return <SliceAndDiceUpsellIAS reportId={reportId} saveUpgradeIdOnStorage />
  }

  const rtChecker = () => {
    if (retailTrustOrganisations.includes(orgId)) {
      return retailTrustAPI[step]
    }
    return actionPlanItems[step] || {}
  }

  const { heading = "", questions } = rtChecker(orgId)

  useEffect(() => {
    const availableQuestions = stepQuestions?.reduce((p, q) => {
      p[q.id] = q.values.score
      return p
    }, {})

    const extractedQuestions = questions?.filter(q => {
      if (Object.keys(availableQuestions).includes(q.id)) {
        q.score = availableQuestions[q.id]
        return q
      }
    })

    setQuestionList(extractedQuestions)
  }, [])

  if (exportingPDF) {
    return (
      <PDFView
        heading={heading}
        device={device}
        step={step}
        questionList={questionList}
      />
    )
  }

  return (
    <Flex width={1 / 1}>
      {surveyReportSixStep?.actionPlanStepId ? (
        <ActionPlanStep
          actionPlanId={surveyReportSixStep.actionPlanStepId}
          score={surveyReportSixStep?.score ?? -1}
          stepQuestions={stepQuestions}
        />
      ) : null}
    </Flex>
  )
}

export default PageManagement
