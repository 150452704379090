import { useFormik } from "formik"
import React, { useEffect } from "react"
import { object, string } from "yup"

import InputInterface from "../../../surveyManagement/template/components/EditBody/InputInterface"

const validationSchema = object({
  answer: string().max(250).required("Cannot be empty")
})

const AnswersWrapper = ({
  labelIndex,
  label,
  questionId,
  setQuestionAnswer,
  stepQuestionId,
  deleteAnswer,
  setFormErrors
}) => {
  const initialValues = {
    answer: label
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setQuestionAnswer(
        formik.values.answer,
        labelIndex,
        questionId,
        stepQuestionId
      )
      setFormErrors(`${questionId}.${labelIndex}.answer`, formik.errors?.answer)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.answer, formik.errors?.answer])

  return (
    <InputInterface
      value={formik.values.answer}
      i={labelIndex}
      handleChange={e => formik.setFieldValue("answer", e.target.value)}
      handleDelete={() => deleteAnswer(labelIndex, questionId, stepQuestionId)}
      errors={formik.errors?.answer}
    />
  )
}

export default AnswersWrapper
