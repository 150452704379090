import { CircularProgress, Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import {
  ScoreLabel,
  getLabelData
} from "../../features/Reports/components/ScoreLabel"

const CustomFlex = styled(Flex)`
  grid-area: B;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
`
const ScoreContainerTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #00000099;

  margin-bottom: 20px;
`

export default function CircularScore({ score }) {
  const scoreValue = !score || score === -1 ? "NA" : score
  const scoreColor =
    !score || score === -1 ? "#00000099" : getLabelData(score).color
  const scoreLabel = !score || score === -1 ? "NA" : score

  return (
    <CustomFlex>
      <ScoreContainerTitle>
        Your overall score for this area:
      </ScoreContainerTitle>
      <CircularProgress
        percentage={scoreValue}
        width="50%"
        strokeColor={scoreColor}
      />
      {scoreLabel === "NA" ? (
        <Flex justifyContent="center" alignItems="center" width="100%" mt={3}>
          <Text fontWeight={500} fontSize={4} width="auto">
            Score not available
          </Text>
        </Flex>
      ) : (
        <ScoreLabel textAlign="center" mt={3} score={score} />
      )}
    </CustomFlex>
  )
}
