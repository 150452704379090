import { Checkbox, Flex, Icon, Spinner, Text } from "@engaging-tech/components"
import React, { useMemo } from "react"
import styled from "styled-components"

import ErrorState from "../../../business-information/components/error-state"
import { filterList } from "./AssignSurveys"

const RoundedList = styled(Flex)`
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.dark[3]}`};
  overflow-x: auto;
`

const CheckboxWrapper = styled(Flex)`
  label {
    width: 100%;
    margin-left: 8px;
  }

  label > div {
    height: 40px;
    width: 100%;
    align-items: center;
  }
`

const HoverText = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`

const HoverFlex = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`

const BodyHeader = ({
  limit,
  currentPage,
  count,
  totalPages,
  handleSelectAll,
  surveys,
  handleRemoveAll,
  itemsSelected
}) => {
  return (
    <Flex
      justifyContent="space-between"
      px={2}
      py={3}
      position="sticky"
      top={0}
      bg="light.0"
      zIndex={2}
    >
      <Flex>
        {count !== 0 ? (
          <Text width="unset">
            Count: {totalPages === currentPage ? count : currentPage * limit} /{" "}
            {count}
          </Text>
        ) : (
          <Text width="unset">Nothing found</Text>
        )}
        {count ? (
          <Text width="unset" marginLeft={20}>
            Selected: {count - (count - itemsSelected)} of {count}
          </Text>
        ) : null}
      </Flex>
      <Flex justifyContent="flex-end">
        <HoverFlex width="120px">
          <Checkbox
            id="assignAll"
            checked={
              !surveys.find(survey => survey.status === filterList[2].value)
            }
            onChange={handleSelectAll}
            label={() => <Text>Select All</Text>}
          />
        </HoverFlex>
        <HoverFlex width="120px" onClick={handleRemoveAll}>
          <Icon name="close" />
          <Text>Remove All</Text>
        </HoverFlex>
      </Flex>
    </Flex>
  )
}

const AssignSurveysBody = ({
  data,
  errors,
  isLoading,
  limit,
  setPage,
  currentPage,
  count,
  totalPages,
  handleChange,
  updateErrors,
  handleSelectAll,
  surveys,
  handleRemoveAll
}) => {
  if (errors || updateErrors) return <ErrorState />

  const itemsSelected = useMemo(() => {
    const initialValue = 0
    const sumOfCheckedItems = data?.reduce(
      (acc, current) =>
        current.status === filterList[1].value ? acc + 1 : acc,
      initialValue
    )
    return sumOfCheckedItems
  }, [data])

  return (
    <>
      <RoundedList
        height="500px"
        width={1 / 1}
        flexDirection="column"
        position="relative"
      >
        <BodyHeader
          limit={limit}
          currentPage={currentPage}
          count={count}
          totalPages={totalPages}
          handleSelectAll={handleSelectAll}
          surveys={surveys}
          handleRemoveAll={handleRemoveAll}
          itemsSelected={itemsSelected}
        />
        {isLoading && (
          <Spinner
            color="primary.0"
            justifyContent="center"
            width={1 / 1}
            height="100px"
          />
        )}
        <Flex flexWrap="wrap">
          {data?.map(survey => (
            <CheckboxWrapper width={1 / 2} key={survey.survey_id}>
              <Checkbox
                mb={2}
                id={survey.survey_id}
                onChange={handleChange}
                disabled={survey.status === filterList[3].value}
                label={() => <Text>{survey.title}</Text>}
                colour={
                  survey.status === filterList[3].value ? "dark.1" : "primary.0"
                }
                checked={
                  survey.status === filterList[1].value ||
                  survey.status === filterList[3].value
                }
                loading={survey?.loading}
              />
            </CheckboxWrapper>
          ))}
        </Flex>
      </RoundedList>
      {currentPage !== totalPages && (
        <HoverText
          width="fit-content"
          fontWeight={600}
          color="primary.0"
          mt={2}
          onClick={() => setPage(currentPage + 1)}
        >
          Load more
        </HoverText>
      )}
    </>
  )
}

export default AssignSurveysBody
