import React, { useMemo } from "react"

import { ActionPlanStepPreview } from "../../../../../components/action-plan/action-plan-step"
import GET_ACTION_PLAN_STEP from "../../../../../graphql/queries/get-action-plan-step"
import useAppSyncQuery from "../../../../../hooks/useAppSyncQuery"
import { LoadingState } from "../../../../surveyManagement/components"

const ActionPlanStep = ({ actionPlanId, score, stepQuestions }) => {
  const { isLoading, data } = useAppSyncQuery(GET_ACTION_PLAN_STEP, {
    variables: { actionPlanStepId: actionPlanId }
  })

  const questions = useMemo(() => {
    if (
      !data?.getActionPlanStep?.actionPlanStep?.questions?.length ||
      !stepQuestions?.length
    ) {
      return []
    }

    const stepQuestionMap = new Map(stepQuestions.map(q => [q.id, q]))

    return data.getActionPlanStep.actionPlanStep.questions.map(
      actionPlanQuestion => {
        const stepQuestion = stepQuestionMap.get(
          actionPlanQuestion.sixStepsQuestionId
        )

        if (stepQuestion?.values?.score != null) {
          return {
            ...actionPlanQuestion,
            score: stepQuestion.values.score
          }
        }
        return { ...actionPlanQuestion, score: -1 }
      }
    )
  }, [data?.getActionPlanStep?.actionPlanStep?.questions, stepQuestions])

  if (isLoading) return <LoadingState message="Loading action plan" />

  if (isLoading || !data) return null

  return (
    <ActionPlanStepPreview
      step={{ ...data?.getActionPlanStep?.actionPlanStep, questions, score }}
    />
  )
}

export default ActionPlanStep
