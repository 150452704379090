import { Flex, Text, TextArea, TextField } from "@engaging-tech/components"
import useStyledTheme from "@engaging-tech/components/dist/customHooks/useStyledTheme"
import { useFormik } from "formik"
import React, { useEffect } from "react"
import styled from "styled-components"
import { object, string } from "yup"

import { setFormErrors } from "../../../../store/actionPlans/actionPlans.actions"

const StyledTextArea = styled(TextArea)`
  width: 100%;
  padding: 0px;
  border-top: none;
  height: 160px;

  textarea {
    height: 100%;
  }
`

const StyledText = styled(Text)`
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`

const isObjEmpty = obj => Object.keys(obj).length === 0

const validationSchema = object({
  title: string().max(250).required(),
  url: string().max(250).required(),
  mediaType: string().max(250).required("media type is a required field"),
  description: string().max(250).required()
})

const AddResource = ({
  resource,
  setResourceTitle,
  setResourceUrl,
  setResourceMediaType,
  setResourceDescription,
  resourceIndex,
  stepQuestionId,
  setEditableIndexes,
  editableIndexes,
  setFormErrors
}) => {
  const theme = useStyledTheme()

  const initialValues = {
    ...resource
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setResourceTitle(formik.values.title, resourceIndex, stepQuestionId)
      setFormErrors(`${resourceIndex}.resourceTitle`, formik.errors?.title)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.title, formik.errors?.title])

  useEffect(() => {
    const handler = setTimeout(() => {
      setResourceUrl(formik.values.url, resourceIndex, stepQuestionId)
      setFormErrors(`${resourceIndex}.url`, formik.errors?.url)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.url, formik.errors?.url])

  useEffect(() => {
    const handler = setTimeout(() => {
      setResourceMediaType(
        formik.values.mediaType,
        resourceIndex,
        stepQuestionId
      )
      setFormErrors(`${resourceIndex}.mediaType`, formik.errors?.mediaType)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.mediaType, formik.errors?.mediaType])

  useEffect(() => {
    const handler = setTimeout(() => {
      setResourceDescription(
        formik.values.description,
        resourceIndex,
        stepQuestionId
      )
      setFormErrors(`${resourceIndex}.description`, formik.errors?.description)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.description, formik.errors?.description])

  const handleAddResource = () => {
    if (!isObjEmpty(formik.errors)) return null
    setEditableIndexes(editableIndexes.filter(num => num !== resourceIndex))
  }

  return (
    <Flex flexDirection="column" width="100%">
      <TextField
        id="mediaType"
        value={formik.values.mediaType}
        onChange={formik.handleChange}
        type="text"
        label="Media type, e.g. Book, Article, Podcast"
        noLabel={!formik.errors?.mediaType}
        borderColor="dark.3"
        labelColor={theme.colors.dark[3]}
        error={formik.errors?.mediaType}
      />
      <TextField
        id="url"
        value={formik.values.url}
        onChange={formik.handleChange}
        type="text"
        label="URL"
        noLabel={!formik.errors?.url}
        borderColor="dark.3"
        labelColor={theme.colors.dark[3]}
        error={formik.errors?.url}
      />

      <TextField
        id="title"
        value={formik.values.title}
        onChange={formik.handleChange}
        type="text"
        label="Title"
        noLabel={!formik.errors?.title}
        borderColor="dark.3"
        labelColor={theme.colors.dark[3]}
        error={formik.errors?.title}
      />
      <StyledTextArea
        id="description"
        value={formik.values.description}
        onChange={formik.handleChange}
        type="text"
        label="Add description (Optional), 250 characters"
        borderColor="dark.3"
        labelColor={theme.colors.dark[3]}
        error={formik.errors?.description}
        noLabel={!formik.errors?.description}
      />
      <Flex justifyContent="space-between" mt={2}>
        <Text>{formik.values.description?.length} / 250</Text>
        <StyledText
          color={!isObjEmpty(formik.errors) ? "dark.3" : "secondary.0"}
          fontWeight={600}
          width="141px"
          disabled={!isObjEmpty(formik.errors)}
          onClick={handleAddResource}
        >
          Add Resource
        </StyledText>
      </Flex>
    </Flex>
  )
}

export default AddResource
