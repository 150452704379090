import { Flex, IconButton, Text } from "@engaging-tech/components"
import React from "react"

import ActionPlanItemActions from "../components/ActionPlanItemActions"
import AssignedTo from "../components/ListActionPlans/AssignedTo"

export const tableHeadings = [
  {
    id: "title",
    title: null
  },
  {
    id: "assignedTo",
    title: "Assigned to"
  },
  {
    id: "createdAt",
    title: null
  },
  {
    id: "status",
    title: (
      <Flex alignItems="center">
        <Text color="dark.1" fontSize={[2, 3]}>
          Status
        </Text>
      </Flex>
    )
  },
  {
    id: "actions",
    title: ""
  }
]

export const tableConfig = {
  stylesPerColumn: [
    {
      height: "60px",
      px: "15px"
    },
    {
      height: "60px",
      px: "15px"
    },
    {
      height: "60px",
      pr: "15px"
    },
    {
      height: "60px",
      pr: "15px"
    },
    {
      height: "60px",
      pr: "15px"
    }
  ],
  columnWidths: ["auto", "auto", "120px", "100px", "300px"],
  main: {
    textAlign: "left",
    overflow: "visible"
  },
  headingPerColumn: [
    {
      py: "20px",
      px: "15px"
    },
    {
      py: "20px",
      px: "15px",
      fontSize: [2, 3],
      fontWeight: 700,
      color: "#282727"
    },
    {
      py: "20px",
      pr: "15px"
    }
  ],
  data: {
    borderTop: "1px solid #EAE9E9",
    color: "#282727",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    textTransform: "capitalize"
  }
}

const StatusEnum = {
  draft: "Draft",
  published: "Published"
}

export const addTableElements = (
  data,
  handleEditClick,
  handlePreviewClick,
  handleCloneClick
) => {
  const rows = data?.map(plan => ({
    ...plan,
    status: plan?.status ? StatusEnum[plan.status] : "-",
    assignedTo: (
      <AssignedTo
        assignedTo={plan?.surveyTitle}
        assignedLength={plan?.surveyCount}
        status={plan?.status}
        id={plan?._id}
      />
    ),
    actions: (
      <ActionPlanItemActions
        id={plan._id}
        isDefault={plan.default}
        handleEditClick={handleEditClick}
        handlePreviewClick={handlePreviewClick}
        handleCloneClick={handleCloneClick}
      />
    )
  }))

  return rows
}

export const generateTableHeadings = (
  tableHeadings,
  titleAsc,
  dateAsc,
  handleSortTitle,
  handleSortDate
) =>
  tableHeadings.map(entry => {
    if (entry.id === "title") {
      return {
        ...entry,
        title: (
          <Flex alignItems="center">
            <Text color="dark.1" fontSize={[2, 3]}>
              Title
            </Text>
            <IconButton
              icon={titleAsc ? "sort-ascending" : "sort-descending"}
              size={24}
              iconColor="dark.1"
              hoverColor="light.0"
              onClick={handleSortTitle}
            />
          </Flex>
        )
      }
    }

    if (entry.id === "createdAt") {
      return {
        ...entry,
        title: (
          <Flex alignItems="center">
            <Text color="dark.1" fontSize={[2, 3]}>
              Date
            </Text>
            <IconButton
              icon={dateAsc ? "sort-ascending" : "sort-descending"}
              size={24}
              iconColor="dark.1"
              onClick={handleSortDate}
              hoverColor="light.0"
            />
          </Flex>
        )
      }
    }

    return entry
  })
