import { Button, Flex, Text } from "@engaging-tech/components"
import React from "react"

const HandleUnauthorised = ({ onCloseModal }) => {
  return (
    <Flex flexDirection="column" width={1 / 1} justifyContent="center">
      <Flex bg="primary.0" p={4}>
        <Text textAlign="center" color="light.0" fontWeight={600} fontSize={5}>
          Unauthorised!
        </Text>
      </Flex>
      <Text p={4} textAlign="center">
        In order to use this feature you first must purchase it! Please talk to
        our customer support team.
      </Text>
      <Flex justifyContent="flex-end" mt={2}>
        <Button width="120px" variant="text.secondary.0" onClick={onCloseModal}>
          Close
        </Button>
      </Flex>
    </Flex>
  )
}

export default HandleUnauthorised
