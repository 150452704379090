import { Spinner } from "@engaging-tech/components"
import { useParams, usePaths, useRouter } from "@engaging-tech/routing"
import React, { createContext, useState } from "react"

import { GET_ACTION_PLAN, PUBLISH_ACTION_PLAN } from "../../../graphql/queries"
import useAppSyncMutation from "../../../hooks/useAppSyncMutation"
import useAppSyncQuery from "../../../hooks/useAppSyncQuery"
import { setActionPlan } from "../../../store/actionPlans/actionPlans.actions"
import ErrorState from "../../business-information/components/error-state"
import ActionPlanInfoHeader from "../components/ActionPlanInfoHeader"
import AssignedToModal from "../components/AssignedToModal"
import HandleUnauthorised from "../components/HandleUnauthorised"
import SixStepsButtons from "../components/SixStepsButtons"
import SixStepsTable from "../components/SixStepsTable"

export const ViewActionPlanContext = createContext()

const ViewActionPlan = ({ sortAssignedSurveys }) => {
  const { id } = useParams()
  const router = useRouter()
  const paths = usePaths()
  console.log("paths", paths)

  const [openModal, setOpenModal] = useState(false)

  const { isLoading, errors, data } = useAppSyncQuery(GET_ACTION_PLAN, {
    variables: { actionPlanId: id },
    successAction: setActionPlan
  })
  const { post, isLoading: isMutationSaving } =
    useAppSyncMutation(PUBLISH_ACTION_PLAN)

  const handlePreviewClick = () =>
    router.navigate(paths.actionPlans.previewActionPlan.replace(":id", id), {
      actionPlanId: id
    })
  const handlePublishClick = () => post({ input: { actionPlanId: id } })

  const contextValues = {
    actionPlan: data?.getActionPlan,
    setOpenModal,
    sortAssignedSurveys,
    handlePreviewClick,
    handlePublishClick
  }

  if (errors) return <ErrorState />
  if (isLoading)
    return <Spinner color="secondary.0" justifyContent="center" width={1 / 1} />

  return (
    <ViewActionPlanContext.Provider value={contextValues}>
      <ActionPlanInfoHeader />
      <SixStepsTable />
      <SixStepsButtons isMutationSaving={isMutationSaving} />
      {openModal && <AssignedToModal />}
    </ViewActionPlanContext.Provider>
  )
}

export default ViewActionPlan
