function processChildren(children) {
  return children
    .map(child => {
      let content = child.text || ""
      if (child.bold) content = `<strong>${content}</strong>`
      if (child.italic) content = `<em>${content}</em>`
      if (child.underline) content = `<u>${content}</u>`
      if (child.code) content = `<code>${content}</code>`
      return content
    })
    .join("")
}

function processList(items) {
  return items
    .map(item => {
      let childrenHtml = processChildren(item.children || [])
      let alignStyle = item.align ? ` style="text-align:${item.align};"` : ""
      if (item.type === "list-item") {
        return `<li>${childrenHtml}</li>`
      } else if (item.type === "numbered-list")
        return `<ol${alignStyle}>${processList(item.children)}</ol>`
      else if (item.type === "bulleted-list")
        return `<ul${alignStyle}>${processList(item.children)}</ul>`
    })
    .join("")
}

export function objectToHtml(json) {
  return json
    ?.map(block => {
      let childrenHtml = processChildren(block.children || [])

      let alignStyle = block.align ? ` style="text-align:${block.align};"` : ""

      switch (block.type) {
        case "paragraph":
          return `<p${alignStyle}>${childrenHtml}</p>`
        case "heading-one":
          return `<h1${alignStyle}>${childrenHtml}</h1>`
        case "heading-two":
          return `<h2${alignStyle}>${childrenHtml}</h2>`
        case "block-quote":
          return `<blockquote${alignStyle}>${childrenHtml}</blockquote>`
        case "numbered-list":
          return `<ol${alignStyle}>${processList(block.children)}</ol>`
        case "bulleted-list":
          return `<ul${alignStyle}>${processList(block.children)}</l>`
        case "list-item":
          return `<li>${childrenHtml}</li>`
        default:
          return childrenHtml
      }
    })
    .join("")
}
