import { Flex, PopUpMenuButton } from "@engaging-tech/components"
import { useFormik } from "formik"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { object, string } from "yup"

import { TextField } from "../../../surveyManagement/components"
import AddOptionButton from "../../../surveyManagement/template/components/EditBody/AddOptionButton"
import PopUpMenu from "../../../surveyManagement/template/components/PopUpMenu/PopUpMenu"
import { MainCard } from "../../../surveyManagement/template/components/TemplateQuestion"
import useGenerateQuestionsMenu from "../../hooks/useGenerateQuestionsMenu"
import AnswersWrapper from "./AnswersWrapper"

const OverflowFlex = styled(Flex)`
  overflow-y: auto;
`

const validationSchema = object({
  title: string().max(250).required("Cannot be empty")
})

const ActionPlanQuestionsBody = ({
  question,
  setQuestionTitle,
  setQuestionAnswer,
  stepQuestionId,
  deleteAnswer,
  removeQuestion,
  swapQuestions,
  addQuestion,
  addLabel,
  questionIndex,
  questionsLength,
  setFormErrors
}) => {
  const [openPopup, setOpenPopup] = useState(false)

  const menu = useMemo(
    () =>
      useGenerateQuestionsMenu(
        questionIndex,
        removeQuestion,
        swapQuestions,
        addQuestion,
        questionsLength,
        stepQuestionId
      ),
    [questionIndex, questionsLength]
  )

  const initialValues = {
    title: question?.title
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setQuestionTitle(formik.values.title, question.id, stepQuestionId)
      setFormErrors(`${questionIndex}.title`, formik.errors?.title)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.title, formik.errors?.title])

  return (
    <MainCard
      padding="24px 34px 24px 24px"
      mt={3}
      flexDirection="column"
      width="100%"
    >
      <TextField
        value={formik.values.title}
        id="title"
        onChange={formik.handleChange}
        error={formik.errors?.title}
      />
      <PopUpMenuButton
        onClick={() => setOpenPopup(!openPopup)}
        size={0}
        icon={openPopup ? "close" : "more_vert"}
      />
      <OverflowFlex flexDirection="column" height="300px" width="100%">
        {question?.answers?.map((label, labelIndex) => (
          <AnswersWrapper
            label={label}
            labelIndex={labelIndex}
            questionId={question?.id}
            setQuestionAnswer={setQuestionAnswer}
            stepQuestionId={stepQuestionId}
            deleteAnswer={deleteAnswer}
            setFormErrors={setFormErrors}
          />
        ))}
      </OverflowFlex>

      {openPopup && <PopUpMenu menu={menu} />}
      <AddOptionButton
        label="Add Option"
        onClick={() => addLabel(question?.id, stepQuestionId)}
      />
    </MainCard>
  )
}

export default ActionPlanQuestionsBody
