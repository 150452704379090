import { gql } from "@apollo/client"

const GET_ACTION_PLAN_STEP = gql`
  query getActionPlanStep($actionPlanStepId: String!) {
    getActionPlanStep(id: $actionPlanStepId) {
      actionPlanStep {
        actionPlanId
        createdAt
        default
        id
        questions {
          body {
            questions {
              answers
              id
              title
            }
            resources {
              description
              mediaType
              title
              url
            }
            suggestedActions
            suggestedPhrases
          }
          sixStepsQuestionId
          title
        }
        title
        type
        updatedAt
      }
      message
      status
    }
  }
`

export default GET_ACTION_PLAN_STEP
