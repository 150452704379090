import { Flex, IconButton, Text } from "@engaging-tech/components"
import React, { useState } from "react"
import styled from "styled-components"

import AssignSurveys from "./AssignSurveys"

const BoxWrapper = styled(Flex)`
  border: ${({ theme }) => `1px solid #EAE9E9`};
  border-radius: 4px;
`

const BorderedWrapper = styled(Flex)`
  border-radius: 4px;
  overflow: hidden;
`

const EllipsisText = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 2px;
`

const AssignedTo = ({ assignedTo, assignedLength, status, id }) => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <>
      {status === "draft" && assignedTo === null ? (
        <BoxWrapper p={2} alignItems="center" justifyContent="space-between">
          <Text></Text>
          <IconButton
            icon="more_horiz"
            size={20}
            iconColor="#EAE9E9"
            onClick={() => setOpenModal(true)}
            disabled
          />
        </BoxWrapper>
      ) : (
        <BoxWrapper p={2} width={1 / 1}>
          <Flex alignItems="center" justifyContent="space-between">
            {assignedTo && assignedTo?.length && (
              <BorderedWrapper bg="dark.4">
                <EllipsisText>{assignedTo}</EllipsisText>
              </BorderedWrapper>
            )}

            <Text style={{ flex: 0.2 }}>
              {assignedTo && assignedLength > 1 && `+${assignedLength}`}
            </Text>

            <IconButton
              icon="more_horiz"
              size={28}
              iconColor="primary.0"
              onClick={() => setOpenModal(true)}
              hoverColor="#FAE2D8"
            />
          </Flex>
        </BoxWrapper>
      )}
      {openModal && (
        <AssignSurveys setOpenModal={setOpenModal} actionPlanId={id} />
      )}
    </>
  )
}

export default AssignedTo
