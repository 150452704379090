import { Flex, RichTextEditor, Text } from "@engaging-tech/components"
import React from "react"

const SuggestedActions = ({
  suggestedActions,
  setSuggestedActions,
  stepQuestionId
}) => {
  const parsedSuggestedActions = suggestedActions?.length
    ? JSON.parse(suggestedActions)
    : suggestedActions

  return (
    <Flex flexDirection="column">
      <Text fontSize={5} fontWeight={600} mt={5} mb={3}>
        Suggested Actions
      </Text>
      <RichTextEditor
        handleChange={value => setSuggestedActions(value, stepQuestionId)}
        initialValue={parsedSuggestedActions}
      />
    </Flex>
  )
}

export default SuggestedActions
