const useGenerateQuestionsMenu = (
  questionIndex,
  removeQuestion,
  swapQuestions,
  addQuestion,
  questionsLength,
  stepQuestionId
) => {
  const entries = [
    {
      icon: "add_circle",
      hovertip: { type: "single", name: "Add question" },
      action: () => addQuestion(questionIndex, stepQuestionId)
    },
    {
      icon: "keyboard_arrow_up",
      hovertip: { type: "single", name: "Move up" },
      action: () =>
        swapQuestions(questionIndex, questionIndex - 1, stepQuestionId)
    },
    {
      icon: "keyboard_arrow_down",
      hovertip: { type: "single", name: "Move down" },
      action: () =>
        swapQuestions(questionIndex, questionIndex + 1, stepQuestionId)
    },
    {
      icon: "delete",
      hovertip: { type: "single", name: "Delete" },
      action: () => removeQuestion(questionIndex, stepQuestionId)
    }
  ]

  const menu = entries.reduce((acc, next) => {
    if (
      (questionIndex !== 0 && next.icon !== "keyboard_arrow_down") ||
      (questionIndex + 1 !== questionsLength &&
        next.icon !== "keyboard_arrow_up")
    )
      acc.push(next)

    return acc
  }, [])

  return menu
}

export default useGenerateQuestionsMenu
