import { Flex, Text } from "@engaging-tech/components"
import React from "react"

import ActionPlanQuestionsBodyContainer from "../../containers/EditActionPlan/ActionPlanQuestionsBodyContainer"

const ActionPlanQuestions = ({ questions, stepQuestionId }) => {
  return (
    <Flex width="96%" flexDirection="column">
      <Text fontSize={5} fontWeight={600} mt={3}>
        Action Plan Questions
      </Text>
      <Flex width={1 / 1} flexDirection="column" alignItems="center">
        {questions?.map((question, questionIndex) => (
          <ActionPlanQuestionsBodyContainer
            key={question.id}
            question={question}
            stepQuestionId={stepQuestionId}
            questionIndex={questionIndex}
            questionsLength={questions?.length}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default ActionPlanQuestions
