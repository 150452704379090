import { Flex, Text } from "@engaging-tech/components"
import React, { useState } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"

import ResourceItem from "./ResourceItem"

const StyledGrid = styled.div`
  margin-top: 20px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  justify-items: center;
  grid-gap: 30px;
`

const StyledText = styled(Text)`
  align-self: end;

  &:hover {
    cursor: pointer;
  }
`

const Resources = ({
  resources,
  stepQuestionId,
  addNewResource,
  deleteResource,
  setResourceTitle,
  setResourceUrl,
  setResourceMediaType,
  setResourceDescription,
  setFormErrors
}) => {
  const [editableIndexes, setEditableIndexes] = useState([])

  const generateEmptyResource = () => {
    const emptyResource = {
      mediaType: "",
      url: "",
      title: "",
      description: ""
    }

    setEditableIndexes([...editableIndexes, resources?.length])
    return emptyResource
  }

  return (
    <Flex flexDirection="column" mb={5}>
      <Text fontSize={5} fontWeight={600} mt={5}>
        Resources
      </Text>
      <StyledGrid>
        {resources?.map((resource, resourceIndex) => (
          <ResourceItem
            key={`resource.${resourceIndex}`}
            resource={resource}
            editableIndexes={editableIndexes}
            setEditableIndexes={setEditableIndexes}
            deleteResource={deleteResource}
            resourceIndex={resourceIndex}
            stepQuestionId={stepQuestionId}
            setResourceTitle={setResourceTitle}
            setResourceUrl={setResourceUrl}
            setResourceMediaType={setResourceMediaType}
            setResourceDescription={setResourceDescription}
            setFormErrors={setFormErrors}
          />
        ))}
      </StyledGrid>
      <StyledText
        color="primary.0"
        fontWeight={600}
        fontSize={5}
        width="fit-content"
        mt={3}
        onClick={() => addNewResource(generateEmptyResource(), stepQuestionId)}
      >
        Add more
      </StyledText>
    </Flex>
  )
}

export default Resources
