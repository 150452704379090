import { Button, Flex, IconButton } from "@engaging-tech/components"
import React from "react"

const ActionPlanItemActions = ({
  id,
  isDefault,
  handleEditClick,
  handleCloneClick,
  handlePreviewClick
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Button
        bg="primary.0"
        color="light.0"
        hoverColor="light.0"
        width={100}
        id={id}
        className="action-plan-clone"
        onClick={handleCloneClick}
      >
        Clone
      </Button>
      <Button
        bg="secondary.0"
        color="light.0"
        hoverColor="light.0"
        width={100}
        id={id}
        className="action-plan-preview"
        onClick={() => handlePreviewClick(id)}
      >
        Preview
      </Button>
      <IconButton
        icon="edit"
        hoverColor="#B9C5CE"
        id={id}
        className="action-plan-edit"
        iconColor={isDefault ? "#B9C5CE" : "#173C5A"}
        disabled={isDefault}
        onClick={() => handleEditClick(id)}
      />
    </Flex>
  )
}

export default ActionPlanItemActions
