import { Flex, Spinner, Table, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useContext, useState } from "react"
import styled from "styled-components"

import {
  addTableElements,
  generateTableHeadings,
  tableConfig,
  tableHeadings
} from "../../lib/listActionPlansTable"
import { ListActionPlansContext } from "../../views/ListActionPlans"
import CloneActionPlan from "./CloneActionPlan"

const StyledTable = styled(Table)`
  border: 1px solid #eae9e9;
  border-radius: 4px;
  margin-bottom: 5px;
`

const ClickableText = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`

const ListActionPlansTable = () => {
  const { actionPlansList, setPage, page, isLoading, setSort, showLoadMore } =
    useContext(ListActionPlansContext)
  const [openModal, setOpenModal] = useState(false)
  const [titleAsc, setTitleAsc] = useState(true)
  const [dateAsc, setDateAsc] = useState(true)
  const router = useRouter()
  const paths = usePaths()

  const handleSortTitle = () => {
    setTitleAsc(!titleAsc)
    setDateAsc(true)
    setSort("title")
  }
  const handleSortDate = () => {
    setDateAsc(!dateAsc)
    setTitleAsc(true)
    setSort("date")
  }
  const handleCloneClick = () => setOpenModal(true)
  const handleEditClick = id =>
    router.navigate(paths.actionPlans.viewActionPlan.replace(":id", id))
  const handlePreviewClick = id =>
    router.navigate(paths.actionPlans.previewActionPlan.replace(":id", id), {
      actionPlanId: id
    })

  const rows = addTableElements(
    actionPlansList,
    handleEditClick,
    handlePreviewClick,
    handleCloneClick
  )

  const headings = generateTableHeadings(
    tableHeadings,
    titleAsc,
    dateAsc,
    handleSortTitle,
    handleSortDate
  )

  return (
    <>
      <Flex flexDirection="column" alignItems="flex-end">
        <StyledTable
          headings={headings}
          rows={rows}
          config={tableConfig}
          mt={3}
        />
        {showLoadMore && (
          <ClickableText
            mt={4}
            fontWeight={600}
            width="fit-content"
            color="primary.0"
            onClick={() => setPage(page)}
          >
            Load More
          </ClickableText>
        )}
      </Flex>
      {isLoading && (
        <Spinner color="secondary.0" justifyContent="center" width={1 / 1} />
      )}
      {openModal && <CloneActionPlan setOpenModal={setOpenModal} />}
    </>
  )
}

export default ListActionPlansTable
